/* centre la carte avec sur une adresse et un zoom */
function codeAddress(region, zoom_level) {
	geocoder.geocode( { 'address': region + ' France'}, function(results, status) {
		if (status == google.maps.GeocoderStatus.OK) {
			var coords = results[0].geometry.location
			map.setCenter(coords);
			map.setZoom(zoom_level);
		}
	});
}

$(document).ready(() => {
	$('li[data-id]').hover(function() {
		$('img[data-id=' + $(this).attr('data-id') + ']').show();
		$('.image_default').hide();

	}, function() {
		$('img[data-id=' + $(this).attr('data-id') + ']').hide();
		$('.image_default').show();
	});

	$( ".shop_online" ).click(function() {
		// $(this).hide();
		$('.list-resellers').addClass("list-resellers-open");
	});

	$( ".link_reparation" ).click(function() {
		$('.infos-map').show();
		$('.reparation').show();
		$('.map_service').show();
		$('.garantie_content').hide();
		$('.contact_content').hide();
		$('.garantie').hide();
		$('.contact').hide();
		$('.link_reparation').addClass('link-active');
		$('.link_garantie').removeClass('link-active');
		$('.link_contact').removeClass('link-active');
		
	});

	$( ".link_garantie" ).click(function() {
		$('.infos-map').hide();
		$('.reparation').hide();
		$('.map_service').hide();
		$('.contact_content').hide();
		$('.garantie_content').show();
		$('.garantie').show();
		$('.contact').hide();
		$('.link_garantie').addClass('link-active');
		$('.link_reparation').removeClass('link-active');
		$('.link_contact').removeClass('link-active');
	});

	$( ".link_contact" ).click(function() {
		$('.infos-map').hide();
		$('.reparation').hide();
		$('.map_service').hide();
		$('.contact_content').show();
		$('.garantie_content').hide();
		$('.garantie').hide();
		$('.contact').show();
		$('.link_garantie').removeClass('link-active');
		$('.link_reparation').removeClass('link-active');
		$('.link_contact').addClass('link-active');
	});


	$( ".burger-menu" ).click(function() {
		$('.burger-menu').toggleClass("burger-active");
		$('nav').toggleClass("menu-responsive");
		$('body').toggleClass("menu-active");
	});

	

	var map = null;
	var geocoder = null;
	var markers = new Object();
	var markerImg;
	var infowindows = new Object();
	var infocol = new Object();


	if (typeof google !== 'undefined') {
		var image = new google.maps.MarkerImage('/dist/img/map_icon.png');
		var shadow = new google.maps.MarkerImage('/dist/img/map_icon.png');
	}

	/*
		*
		* Initialise la carte
		*/
	function initialize() {
		//centre sur la Belgique
		var latlng = new google.maps.LatLng(50.51982,4.61426);
		var myOptions = {
			zoom: 7,
			center: latlng,
			mapTypeId: google.maps.MapTypeId.ROADMAP
		};

		map = new google.maps.Map(document.getElementById("map"),myOptions);

		geocoder = new google.maps.Geocoder();


		//Les markers
		markers[0] = new google.maps.Marker({
			position: new google.maps.LatLng(50.6733574,5.5248457),
			title:"BP-ELECTRO (Librairie DEWOLF)",
			shadow: shadow,
			icon: image,
			map: map
		});infowindows[0] = new google.maps.InfoWindow({
			content: "<h3 class=\"name\">BP-ELECTRO (Librairie DEWOLF)</h3><address>Avenue du Progrès 3<br />4432 ALLEUR<br /><br /><br>Point de dépôt/ Produit: complet, propre et bien emballé/ Pas de vente de pièces ni d'accessoires<br><a href=\"mailto:bpelectro.sav@gmail.com\">bpelectro.sav@gmail.com</a></address>"
		});infocol[0] = "<h3 class=\"name\">BP-ELECTRO (Librairie DEWOLF)</h3><address>Avenue du Progrès 3<br />4432 ALLEUR<br /><br />Point de dépôt/ Produit: complet, propre et bien emballé/ Pas de vente de pièces ni d'accessoires<br><a href=\"mailto:bpelectro.sav@gmail.com\">Contact</a></address>";google.maps.event.addListener(markers[0], 'click', function() {
			$.each(infowindows, function(i,infowindow) {
				infowindow.close();
			});
			infowindows[0].open(map,markers[0]);
		});markers[1] = new google.maps.Marker({
			position: new google.maps.LatLng(50.589545,4.35707),
			title:"GROUPE SEB magasin d'usine",
			shadow: shadow,
			icon: image,
			map: map
		});infowindows[1] = new google.maps.InfoWindow({
			content: "<h3 class=\"name\">GROUPE SEB magasin d'usine</h3><address>Rue de L'Industrie, 14<br />1400 NIVELLES<br /><br /><br>Point de dépôt/ Produit: complet, propre et bien emballé/ Pas de vente de pièces ni d'accessoires<br><a href=\"mailto:info@vls.be\">info@vls.be</a></address>"
		});infocol[1] = "<h3 class=\"name\">GROUPE SEB magasin d'usine</h3><address>Rue de L'Industrie, 14<br />1400 NIVELLES<br /><br />Point de dépôt/ Produit: complet, propre et bien emballé/ Pas de vente de pièces ni d'accessoires<br><a href=\"mailto:info@vls.be\">Contact</a></address>";google.maps.event.addListener(markers[1], 'click', function() {
			$.each(infowindows, function(i,infowindow) {
				infowindow.close();
			});
			infowindows[1].open(map,markers[1]);
		});markers[2] = new google.maps.Marker({
			position: new google.maps.LatLng(50.45752,4.480787),
			title:"BP Electro",
			shadow: shadow,
			icon: image,
			map: map
		});infowindows[2] = new google.maps.InfoWindow({
			content: "<h3 class=\"name\">BP Electro</h3><address>Rue du Vigneron 123<br />6043 Ransart<br />00352.37.20.44.401<br /><br>Réparateur & revendeur de pièces et accessoires / Produit: complet et propre<br><a href=\"mailto:bpelectro.sav@gmail.com\">bpelectro.sav@gmail.com</a></address>"
		});infocol[2] = "<h3 class=\"name\">BP Electro</h3><address>Rue du Vigneron 123<br />6043 Ransart<br />00352.37.20.44.401<br />Réparateur & revendeur de pièces et accessoires / Produit: complet et propre<br><a href=\"mailto:bpelectro.sav@gmail.com\">Contact</a></address>";google.maps.event.addListener(markers[2], 'click', function() {
			$.each(infowindows, function(i,infowindow) {
				infowindow.close();
			});
			infowindows[2].open(map,markers[2]);
		});markers[3] = new google.maps.Marker({
			position: new google.maps.LatLng(49.5641889,6.0780664),
			title:"SER-TEC",
			shadow: shadow,
			icon: image,
			map: map
		});infowindows[3] = new google.maps.InfoWindow({
			content: "<h3 class=\"name\">SER-TEC</h3><address>Rue du Château d'eau 5<br />L3364 LEUDELANGE<br />00352.37.20.44.401<br /><br>Réparateur & revendeur de pièces et accessoires / Produit: complet et propre<br><a href=\"mailto:sertec@grlou.com\">sertec@grlou.com</a></address>"
		});infocol[3] = "<h3 class=\"name\">SER-TEC</h3><address>Rue du Château d'eau 5<br />L3364 LEUDELANGE<br />00352.37.20.44.401<br />Réparateur & revendeur de pièces et accessoires / Produit: complet et propre<br><a href=\"mailto:sertec@grlou.com\">Contact</a></address>";google.maps.event.addListener(markers[3], 'click', function() {
			$.each(infowindows, function(i,infowindow) {
				infowindow.close();
			});
			infowindows[3].open(map,markers[3]);
		});markers[4] = new google.maps.Marker({
			position: new google.maps.LatLng(50.9396206,5.353575),
			title:"SERVILUX-HASSELT",
			shadow: shadow,
			icon: image,
			map: map
		});infowindows[4] = new google.maps.InfoWindow({
			content: "<h3 class=\"name\">SERVILUX-HASSELT</h3><address>Zeilstraat, 12<br />3500 HASSELT<br />011/28.17.80<br /><br>Réparateur & revendeur de pièces et accessoires / Produit: complet et propre<br><a href=\"mailto:info@servilux.be\">info@servilux.be</a></address>"
		});infocol[4] = "<h3 class=\"name\">SERVILUX-HASSELT</h3><address>Zeilstraat, 12<br />3500 HASSELT<br />011/28.17.80<br />Réparateur & revendeur de pièces et accessoires / Produit: complet et propre<br><a href=\"mailto:info@servilux.be\">Contact</a></address>";google.maps.event.addListener(markers[4], 'click', function() {
			$.each(infowindows, function(i,infowindow) {
				infowindow.close();
			});
			infowindows[4].open(map,markers[4]);
		});

	}
	initialize();
});



$('.product_link-filter').on("click", function() {
	$('.test-filter').show();
	$('.test-filter[data-product=' + $(this).attr('data-product') + ']').hide();


	
}, function() {
	$('.test-filter').hide();
	$('.test-filter[data-product=' + $(this).attr('data-product') + ']').show();

});

$('.product_link-filter').on("click", function() {
	$('.product_link-filter').removeClass('active-filter');
	$(this).addClass('active-filter');
});

$('.product_link-filter-all').on("click", function() {
	$('.test-filter').show();
});



// GDPR MODAL - COOKIE BAR 

$('.btn-choice').click(function() {
	$(this).parents('.category').toggleClass('active');
});


$('.cookie_infos').click(function() {
	$(".modal-gdpr").addClass("show");
});

$('.close').click(function() {
	$(".modal-gdpr").removeClass("show");
});


$('.open-cookie').click(function() {
	$(".cookie-bar").removeClass("noshow");
	return false;
});

var setCookie = function(name, value) {
	var d = new Date;
	d.setTime(d.getTime() + (5 * 365 * 24 * 60 * 60 * 1000));
	document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}

$('.accept_cookie').click(function() {
	$('.btn-save').click();
	
	return false;
});

var gdprGetParams = {};
$('.btn-save').click(function(){
	var categories = $('.category.active');
	categories.each(function(){
		var track = $(this).data('tracking');
		gdprGetParams[track] = 1;
	});

	setCookie('gdpr-settings', JSON.stringify(gdprGetParams));
	
	$(".modal-gdpr").removeClass("show");
	$(".cookie-bar").addClass("noshow");

	const xhr = new XMLHttpRequest();

	xhr.open('GET', '/resources/analytics');
	xhr.onload = () => {
		if (xhr.status == 200) parseScripts(xhr.responseText);
	};
	xhr.send();

	return false;
});

function parseScripts (responseText) {
	const parser = new DOMParser();
	const scripts = parser.parseFromString(responseText, 'text/html');

	Array.prototype.forEach.call(scripts.querySelectorAll('script'), (script) => {
		const s = document.createElement('script');
		const arr = Array.from(script.attributes);

		for (let i = 0; i < arr.length; i++)
			s.setAttribute(arr[i].name, arr[i].value);

		s.textContent = script.textContent;

		document.body.appendChild(s);
	});
}

/*
	Commerce Connector click detection + event sending
*/
$('body').on('click', 'a[href*="redirect.global.commerce-connector.com"]', function () {
	if (gtag) gtag('event', 'click', {
		'event_category': 'buyOnlineButton',
		'event_label': $(this).data('shop-name')
	});
});